export const hero = {
  title: "Modern enterprise POS for your clients",
  subTitle: "Enterprise Food Services",
  subtext:
    "Upgrade your clients to an omnichannel, cloud-based POS system and have a real-time look at the operational health of the location you manage.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/enterprise-food-services/demo",
  },
};

export const image = {
  imageName: "enterprise-food-hero.png",
  mobileImageName: "enterprise-food-hero-mobile.png",
  title: "ENTERPRISE FOOD SERVICES",
};

export const highlightsSection = {
  title: "Introduce your clients to the cloud",
  imageName: "enterprise-food-highlights.png",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/enterprise-food-services/demo",
  },
  highlights: [
    {
      title: "Faster \n transactions",
      subtext:
        "Shorten lines and keep guests moving with omnichannel POS, featuring intuitive interfaces, easy-to-use discount engine, and versatile payment options.",
    },
    {
      title: "Automatic \n offline mode",
      subtext:
        "Process orders and payments at full speed even during connection interruptions with a proprietary offline mode that requires no staff interaction.",
    },
    {
      title: "Flexible \n hardware offerings",
      subtext:
        "Best-in-class fixed, portable, handheld and self-service hardware that conforms to each property's operations and communicates with a centralized POS platform.",
    },
  ],
};

export const highlightsHero = {
  image: {
    imageName: "enterprise-food-highlights-2.png",
    mobileImageName: "enterprise-food-highlights-2-mobile.png",
    title: "World-class technology and service",
    subtext: "ENTERPRISE SOLUTIONS",
  },
  title: "Gain a clear picture of all your managed properties",
  highlights: [
    {
      title: "Complete \n visibility",
      subtext:
        "Track the health of any and all facilities under your portfolio at any moment with cloud-based enterprise management controls.",
    },
    {
      title: "Permissions for \n every level",
      subtext:
        "Fix operational challenges with management controls for enterprise, operational, and ordering needs so sensitive data is always protected.",
    },
    {
      title: "More influential \n insights",
      subtext:
        "Spot trends, identify areas for improvement, and create new strategies to increase revenue in real-time based on all of your managed properties.",
    },
  ],
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/enterprise-food-services/demo",
  },
};

export const partners = {
  title: "Partnered with the leaders in your industry",
  desktopImageName: "appetize-enterprise-food-partners-desktop.png",
  mobileImageName: "appetize-enterprise-food-partners-mobile.png",
};

export const heroQuote = {
  title: "Powering efficiency and better experiences",
  quote: `“SpotOn has been a great partner that has driven both innovation and efficiency within our operations. Using SpotOn has helped us to enhance the fan experience by providing more flexible ordering and payment solutions that improve the speed of service to our customers.”`,
  personName: "Mike Morris",
  personPosition: "CIO Legends Hospitality",
  imageName: "enterprise-food-hero-quote.png",
  mobileImageName: "enterprise-food-hero-quote-mobile.png",
};

export const productsWithLinks = {
  title:
    "Built on a true omnichannel platform to support the evolution of enterprise food service",
  subtext:
    "Centralize data collection from all managed properties to obtain an accurate, real-time understanding of exactly how each organization is performing. Modern omnichannel enterprise food service POS solutions from SpotOn keep the companies you manage on the cutting edge of guest service.",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform your guest experience, provide valuable business insights, and deliver ROI to your enterprise food service.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/enterprise-food-services/demo",
  },
};
